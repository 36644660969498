import React, { useState } from "react";
import { Box, Typography, Stack } from "@mui/material";
import { useNavigate } from "react-router-dom";

// assets
import logo from "../../assets/logo.png";

// mui icons
import BackToLogin from "../../components/BackToLogin/index";
import ResetPassword from "../../components/ResetPassword/index";
import SendOtp from "../../components/SendOtp/index";
import VerifyOtp from "../../components/VerifyOtp/index";

const ForgotPassword = () => {
  const navigate = useNavigate();
  const [activeStack, setActiveStack] = useState(1);

  const sendOtpHandler = () => {
    setActiveStack(2);
  };

  const verifyOtpHandler = () => {
    setActiveStack(3);
  };

  const resetPasswordHandler = () => {
    setActiveStack(4);
  };

  const backtoLoginHandler = () => {
    navigate("/login");
  };

  return (
    <Box>
      <Box
        sx={{
          height: "100vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "flex-start",
          paddingTop: "100px",
        }}
      >
        <Stack
          sx={{ height: "300px", width: "40%" }}
          justifyContent="center"
          alignItems="center"
          gap={5}
        >
          <img
            src={logo}
            alt="logo"
            style={{
              width: "246px",
            }}
          ></img>
          <Typography
            sx={{
              fontSize: "30px",
              fontWeight: "600",
              lineHeight: "45px",
              letterSpacing: "0.10000000149011612px",
              textAlign: "center",
              cursor: "default",
            }}
          >
            {activeStack === 4 ? "Recovery Password" : "Forgot Password"}
          </Typography>

          {activeStack === 1 && <SendOtp sendOtpHandler={sendOtpHandler} />}

          {activeStack === 2 && (
            <VerifyOtp verifyOtpHandler={verifyOtpHandler} />
          )}

          {activeStack === 3 && (
            <ResetPassword resetPasswordHandler={resetPasswordHandler} />
          )}

          {activeStack === 4 && (
            <BackToLogin backtoLoginHandler={backtoLoginHandler} />
          )}
        </Stack>
      </Box>
    </Box>
  );
};

export default ForgotPassword;
