import { Box, Stack, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import MainHeader from "../../shared/MainHeader";
import "react-big-calendar/lib/css/react-big-calendar.css";

import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import { GET } from "../../api/ApiService";
import EncryptionHelper from "../../shared/EncryptionHelper";
import { useSelector } from "react-redux";

const DashboardPage = () => {
  const userDetails = useSelector((state) => state.userReducer.userDetails);

  const localizer = momentLocalizer(moment);
  const [events, setEvents] = useState([]);
  const [data, setData] = useState([]);
  const [casetype, setCasetype] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await GET(
          `case/dashboard-data?userid=${userDetails?.id}`
        );

        const encryptedData = response?.encryptedData;
        const decryptedData = EncryptionHelper.decryptData(encryptedData);
        console.log("decryptedData of case dashboard respo =>", decryptedData);

        const case_types = [];

        decryptedData.forEach((element) => {
          if (!case_types.includes(element?.CaseType)) {
            case_types.push(element?.CaseType);
          }
        });

        setCasetype(case_types);

        setData(decryptedData);

        const eventList = decryptedData.map((item) => {
          const adjournedDate = new Date(`${item.AdjournedDate}T09:00:00`);
          const endDate = new Date(adjournedDate.getTime() + 60 * 60 * 1000);

          return {
            title: `${item.CaseName} Adjourned`,
            start: adjournedDate,
            end: endDate,
          };
        });
        setEvents(eventList);
      } catch (err) {
        console.warn("err --------", err);
      }
    };

    fetchData();
  }, []); // Ensure dependency array is provided, here it's empty as you need it to run only once

  // Function to format the title with case name and adjourned date
  const eventTitleFormatter = (event) => {
    const formattedDate = moment(event.start).format("MMMM Do YYYY, h:mm a");
    return `${event.title} - Adjourned on ${formattedDate}`;
  };

  return (
    <Box>
      <MainHeader headingText="User Adjourned Case Calendar" />
      <Stack
        p={4}
        gap={4}
        sx={{
          border: "1px solid #C1C1C1",
          marginBottom: "20px",
        }}
      >
        <Stack
          direction={"row"}
          justifyContent={"space-between"}
          alignItems={"center"}
          pl={2}
          pr={2}
        >
          <Stack
            direction={"row"}
            justifyContent={"space-between"}
            alignItems={"center"}
            gap={2}
          >
            <Typography>Total Cases :</Typography>
            <Typography> {data?.length}</Typography>
          </Stack>

          <Stack
            direction={"row"}
            justifyContent={"space-between"}
            alignItems={"center"}
            gap={2}
          >
            <Typography>Case Types :</Typography>
            <Typography>
              {" "}
              {casetype?.map((item, index) => (
                <span key={index}>
                  {" "}
                  {index === casetype?.length - 1 && casetype?.length > 1
                    ? "and"
                    : ""}
                  &nbsp;
                  {item}{" "}
                  {index === casetype?.length - 2 ||
                  index === casetype?.length - 1
                    ? ""
                    : ","}
                </span>
              ))}
            </Typography>
          </Stack>
        </Stack>

        <div style={{ height: 500 }}>
          <Calendar
            localizer={localizer}
            events={events}
            startAccessor="start"
            endAccessor="end"
            style={{ margin: "0 20px" }}
            components={{
              event: ({ event }) => <span>{eventTitleFormatter(event)}</span>,
            }}
          />
        </div>
      </Stack>
    </Box>
  );
};

export default DashboardPage;
