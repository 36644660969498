import axios from "axios";

const baseURL = process.env.REACT_APP_API_BASE_URL;
const apiKEY = process.env.REACT_APP_API_KEY;

// Function to retrieve token from localStorage
const getToken = () => {
  return localStorage.getItem("token");
};

// Function to generate authorization header
const getAuthorizationHeader = (authRequired = true) => {
  if (authRequired) {
    const token = getToken();
    return token ? `${token}` : undefined;
  }
  return undefined;
};

// Function to handle response
const handleResponse = (response) => {
  try {
    const status = parseInt(response.status, 10);
    if (status === 200 || status === 201) {
      return response.data;
    }
    throw new Error(`Request failed with status code ${status}`);
  } catch (error) {
    console.error("Error handling response:", error);
    throw error;
  }
};

// Function to perform GET request
export const GET = async (endpoint, params = {}, authRequired = true) => {
  try {
    const response = await axios.get(`${baseURL}/${endpoint}`, {
      params,
      headers: {
        "Content-Type": "application/json",
        Authorization: getAuthorizationHeader(authRequired),
        "X-API-Key": apiKEY,
      },
    });
    return handleResponse(response);
  } catch (error) {
    console.error("Error making GET request:", error);
    if (error?.response?.status === 401) {
      localStorage.removeItem("token");
      window.location.href = "/login";
    }
    throw error;
  }
};

export const POST = async (
  endpoint,
  body = {},
  authRequired = true,
  isformData = false
) => {
  try {
    const response = await axios.post(`${baseURL}/${endpoint}`, body, {
      headers: {
        "Content-Type": isformData ? "multipart/form-data" : "application/json",
        Authorization: getAuthorizationHeader(authRequired),
        "X-API-Key": apiKEY,
      },
    });
    return handleResponse(response);
  } catch (error) {
    console.error("Error making POST request =>", error);
    if (error.response.status === 401) {
      localStorage.removeItem("token");
      window.location.href = "/login";
    }
    throw error;
  }
};

// Function to perform PUT request
export const PUT = async (endpoint, body, authRequired = true) => {
  try {
    const response = await axios.put(`${baseURL}/${endpoint}`, body, {
      headers: {
        "Content-Type": "application/json",
        Authorization: getAuthorizationHeader(authRequired),
        "X-API-Key": apiKEY,
      },
    });
    return handleResponse(response);
  } catch (error) {
    console.error("Error making PUT request:", error);
    if (error.response.status === 401) {
      localStorage.removeItem("token");
      window.location.href = "/login";
    }
    throw error;
  }
};

export const DELETE = async (
  endpoint,
  requestBody, // Renamed from 'body' to clarify it's the request body
  params = {},
  authRequired = true
) => {
  try {
    const response = await axios.delete(`${baseURL}/${endpoint}`, {
      data: requestBody, // Include the request body in the 'data' property
      params,
      headers: {
        "Content-Type": "application/json",
        Authorization: getAuthorizationHeader(authRequired),
        "X-API-Key": apiKEY,
      },
    });
    return handleResponse(response);
  } catch (error) {
    console.error("Error making DELETE request:", error);
    if (error.response.status === 401) {
      localStorage.removeItem("token");
      window.location.href = "/login";
    }
    throw error;
  }
};


