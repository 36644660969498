import { Grid, Stack, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import TextInput from "../../shared/TextInput";

import ButtonCommon from "../../shared/ButtonCommon";
import NumberInput from "../../shared/NumberInput";
// import TextAreaInput from "../../shared/TextAreaInput";
import DateInput from "../../shared/DateInput";
import SelectInput from "../../shared/SelectInput";

import { useFormik } from "formik";
import * as Yup from "yup";
import { GET, PUT } from "../../api/ApiService";

import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import CheckboxInput from "../../shared/CheckboxInput";
import { useSelector } from "react-redux";
import EncryptionHelper from "../../shared/EncryptionHelper";
import CloseIcon from "@mui/icons-material/Close";
import RichTextEditor from "../../shared/RichTextEditor";
import {
  courtDropdown,
  judgeDropdown,
  statusData,
  appearanceReport,
  caseTypeData,
  serviceLocationData,
} from "../AddEditConstants/index";
import UserSearchDropdown from "../../shared/UserSearchDropdown";
import DarkLightMode from "../../shared/DarkLightMode";
import { useNavigate } from "react-router-dom";

const EditCase = () => {
  const userDetails = useSelector((state) => state.userReducer.userDetails);
  const editData = useSelector((state) => state.editcaseReducer.editCase);

  const navigate = useNavigate();

  const [swData, setSwData] = useState([]);
  // const [assignToDropdown, setAssignToDropdown] = useState([]);
  const [resetDropdown, setResetDropdown] = useState(false);

  useEffect(() => {
    // GET("users?role=5")
    //   .then((resp) => {
    //     const encryptedData = resp?.encryptedData;
    //     const decryptedData = EncryptionHelper.decryptData(encryptedData);

    //     setSwData(decryptedData?.users);
    //   })
    //   .catch((err) => {
    //     console.warn("error =>", err);
    //   });

    GET("users")
      .then((resp) => {
        const encryptedData = resp?.encryptedData;
        const decryptedData = EncryptionHelper.decryptData(encryptedData);

        console.log("decryptedData  of users ===>", decryptedData);

        setSwData(decryptedData?.users);
      })
      .catch((err) => {
        console.warn("error =>", err);
      });
  }, []);

  // Reset the dropdown reset state after it has been set to true
  useEffect(() => {
    if (resetDropdown) {
      setResetDropdown(false);
    }
  }, [resetDropdown]);

  const permissionsIdArray = userDetails?.permission?.map((item) => {
    return item.permission_id;
  });

  const [editorValue, setEditorValue] = useState(editData?.Comments);

  const handleEditorChange = (value) => {
    setEditorValue(value);
    formik.setFieldValue("editorContent", value);

    if (value == "<p><br></p>") {
      setEditorValue("");
      formik.setFieldValue("editorContent", "");
    }
  };

  const secondarySWEditData = editData?.sw_user?.map((item) => item.UserID);

  const formik = useFormik({
    initialValues: {
      caseNumber: editData?.CaseNumber,
      caseName: editData?.CaseName,
      docketNumber: editData?.DocketIndexNumber,
      voucherNumber: editData?.VoucherNumber,
      assignedTo: {
        label: editData?.AssignedToUser,
        id: editData?.AssignedToUserID,
      },
      secondarySW: editData?.sw_user ? secondarySWEditData : [],
      status: editData?.Status,
      hoursLimit: editData?.HoursLimit,
      comments: editData?.Comments,
      editorContent: editData?.Comments,
      receivedDate: editData?.ReceivedDate,
      adjournedDate: editData?.AdjournedDate,
      adjournedTime: editData?.AdjournedTime,
      reportDueDate: editData?.ReportDueDate,
      court: editData?.Court,
      judge: editData?.Judge,
      reportContactInfo: editData?.ReportContactInfo,
      appearance: editData?.Appearance,
      report: editData?.Report,
      readyToAssign: editData?.ReadyToAssign === 1 ? true : false,
      emailSent: editData?.EmailSent === 1 ? true : false,
      sealed: editData?.Sealed === 1 ? true : false,
      billed: editData?.Billed === 1 ? true : false,
      needsCorrectOrder: editData?.NeedsCorrectOrder === 1 ? true : false,
      caseType: editData?.CaseType,
      preferredAvailability: editData?.PreferredAvailability,
      serviceType: editData?.ServiceType,
      serviceLocation: editData?.ServiceLocation,
      supervisedVisits: editData?.ServiceType?.includes("Supervised Visits")
        ? true
        : false,
      therapeuticVisits: editData?.ServiceType?.includes("Therapeutic Visits")
        ? true
        : false,
      observationEvaluation: editData?.ServiceType?.includes(
        "Observation and Evaluation"
      )
        ? true
        : false,
      virtualVisit: editData?.ServiceType?.includes("Virtual Visit")
        ? true
        : false,
      sandwichVisits: editData?.ServiceType?.includes("Sandwich Visits")
        ? true
        : false,
      homeStudy: editData?.ServiceType?.includes("Home Study") ? true : false,
      visitingCoach: editData?.ServiceType?.includes("Visiting Coach")
        ? true
        : false,
      parentCoordinator: editData?.ServiceType?.includes("Parent Coordinator")
        ? true
        : false,
      supervisedAccess: editData?.ServiceType?.includes("Supervised Access")
        ? true
        : false,
      dropIns: editData?.ServiceType?.includes("Drop Ins") ? true : false,
      advocacy: editData?.ServiceType?.includes("Advocacy") ? true : false,
      other: editData?.ServiceType?.includes("Other") ? true : false,
      unknown: editData?.ServiceType?.includes("Unknown") ? true : false,
    },
    validationSchema: Yup.object({
      caseNumber: Yup.string().required("Case number is required"),
      caseName: Yup.string().required("Case name is required"),
      status: Yup.string().required("Status is required"),
      hoursLimit: Yup.number().required("Hours limit is required"),
      caseType: Yup.string().required("Case type is required"),
      // editorContent: Yup.string().required("Content is required"),
    }),
    onSubmit: (values) => {
      const putData = {
        CaseNumber: values.caseNumber,
        CaseName: values.caseName,
        DocketIndexNumber: values.docketNumber,
        VoucherNumber: values.voucherNumber,
        AssignedToUserID: values.assignedTo ? values.assignedTo?.id : null,
        SecondarySWUserID: values.secondarySW,
        HoursLimit: values.hoursLimit,
        Comments: values.editorContent,
        ReportDueDate: values.reportDueDate,
        ReceivedDate: values.receivedDate,
        AdjournedDate: values.adjournedDate,
        AdjournedTime: values.adjournedTime,
        Court: values.court,
        Judge: values.judge,
        ReportContactInfo: values.reportContactInfo,
        Appearance: values.appearance,
        Report: values.report,
        CaseType: values.caseType,
        PreferredAvailability: values.preferredAvailability,
        ServiceType: [
          values.supervisedVisits && "Supervised Visits",
          values.therapeuticVisits && "Therapeutic Visits",
          values.observationEvaluation && "Observation and Evaluation",
          values.virtualVisit && "Virtual Visit",
          values.sandwichVisits && "Sandwich Visits",
          values.homeStudy && "Home Study",
          values.visitingCoach && "Visiting Coach",
          values.parentCoordinator && "Parent Coordinator",
          values.supervisedAccess && "Supervised Access",
          values.dropIns && "Drop Ins",
          values.advocacy && "Advocacy",
          values.other && "Other",
          values.unknown && "Unknown",
        ].filter(Boolean),
        ServiceLocation: values.serviceLocation,
        ModifiedByUserID: userDetails?.id,
        Status: values.status,
        ReadyToAssign: values.readyToAssign,
        EmailSent: values.emailSent,
        Sealed: values.sealed,
        Billed: values.billed,
        NeedsCorrectOrder: values.needsCorrectOrder,
      };

      console.log("put data to be encrypted ====>", putData);

      const encryptData = EncryptionHelper.encryptData(putData);

      PUT(`case/update-case/${editData?.CaseID}`, { encryptData })
        .then((resp) => {
          toast.success(resp?.message);
          window.history.back();
        })
        .catch((err) => {
          toast.error(err?.response?.data?.message);
        });
    },
  });

  // useEffect(() => {
  //   console.log("Form values changed:", formik.values.secondarySW);

  //   if (formik.values.secondarySW.length > 0) {
  //     const assignToDropdownData = allusers?.map((item) => {
  //       return {
  //         value: item.UserID,
  //         label: item.Username,
  //       };
  //     });

  //     let newarr = assignToDropdownData;

  //     for (let i = 0; i < formik.values.secondarySW.length; i++) {
  //       const filteredArray = newarr.filter(
  //         (item) => item.value !== formik.values.secondarySW[i]
  //       );
  //       newarr = filteredArray;
  //     }
  //     setAssignToDropdown(newarr);
  //   } else {
  //     const assignToDropdownData = allusers?.map((item) => {
  //       return {
  //         value: item.UserID,
  //         label: item.Username,
  //       };
  //     });
  //     setAssignToDropdown(assignToDropdownData);
  //   }
  // }, [formik.values.secondarySW]);

  // useEffect(() => {
  //   console.log("fomik values ====>", formik.values);
  // }, [formik.values]);

  return (
    <Stack gap={4} pl={1}>
      <Stack
        direction={"row"}
        justifyContent={"space-between"}
        alignItems={"center"}
      >
        <Typography variant="h4">Update case</Typography>
        <Stack sx={{ cursor: "pointer" }} flexDirection={"row"} gap={2}>
          <DarkLightMode />

          <CloseIcon
            fontSize="large"
            sx={{ cursor: "pointer" }}
            onClick={() => {
              // window.history.back();
              navigate(-1);
            }}
          />
        </Stack>
      </Stack>
      <form onSubmit={formik.handleSubmit}>
        <Grid container spacing={4}>
          {/* 1  */}
          <Grid item xs={12} sm={6}>
            <Stack>
              {" "}
              <Typography sx={{ textAlign: "left" }}>
                Case Number<span style={{ color: "red" }}>*</span>
              </Typography>
              <Stack sx={{ width: "100%" }}>
                {" "}
                <TextInput
                  name="caseNumber"
                  placeholder=""
                  onChangeValue={formik.handleChange}
                  onBlurValue={formik.handleBlur}
                  value={formik.values.caseNumber}
                  startIcon={false}
                />
                {formik.touched.caseNumber && formik.errors.caseNumber ? (
                  <div
                    style={{
                      fontSize: "smaller",
                      color: "red",
                      textAlign: "left",
                    }}
                  >
                    {formik.errors.caseNumber}
                  </div>
                ) : null}
              </Stack>
            </Stack>
          </Grid>
          {/* 2  */}
          <Grid item xs={12} sm={6}>
            <Stack>
              {" "}
              <Typography sx={{ textAlign: "left" }}>
                Case Name<span style={{ color: "red" }}>*</span>
              </Typography>
              <Stack sx={{ width: "100%" }}>
                <TextInput
                  name="caseName"
                  placeholder=""
                  onChangeValue={formik.handleChange}
                  onBlurValue={formik.handleBlur}
                  value={formik.values.caseName}
                  startIcon={false}
                />

                {formik.touched.caseName && formik.errors.caseName ? (
                  <div
                    style={{
                      fontSize: "smaller",

                      color: "red",
                      textAlign: "left",
                    }}
                  >
                    {formik.errors.caseName}
                  </div>
                ) : null}
              </Stack>
            </Stack>
          </Grid>

          {/* 3  */}
          <Grid item xs={12} sm={6}>
            <Stack>
              {" "}
              <Typography sx={{ textAlign: "left" }}>
                Docket/Index Number
              </Typography>
              <Stack sx={{ width: "100%" }}>
                <TextInput
                  name="docketNumber"
                  placeholder=""
                  onChangeValue={formik.handleChange}
                  onBlurValue={formik.handleBlur}
                  value={formik.values.docketNumber}
                  startIcon={false}
                />
                {formik.touched.docketNumber && formik.errors.docketNumber ? (
                  <div
                    style={{
                      fontSize: "smaller",

                      color: "red",
                      textAlign: "left",
                    }}
                  >
                    {formik.errors.docketNumber}
                  </div>
                ) : null}{" "}
              </Stack>
            </Stack>
          </Grid>

          {/* 4  */}
          <Grid item xs={12} sm={6}>
            <Stack>
              {" "}
              <Typography sx={{ textAlign: "left" }}>Voucher Number</Typography>
              <Stack sx={{ width: "100%" }}>
                <TextInput
                  name="voucherNumber"
                  placeholder=""
                  onChangeValue={formik.handleChange}
                  onBlurValue={formik.handleBlur}
                  value={formik.values.voucherNumber}
                  startIcon={false}
                />

                {formik.touched.voucherNumber && formik.errors.voucherNumber ? (
                  <div
                    style={{
                      fontSize: "smaller",

                      color: "red",
                      textAlign: "left",
                    }}
                  >
                    {formik.errors.voucherNumber}
                  </div>
                ) : null}
              </Stack>
            </Stack>
          </Grid>

          {/* 5  */}
          <Grid item xs={12} sm={6}>
            <Stack>
              <Typography sx={{ textAlign: "left" }}>Assigned To</Typography>
              <Stack sx={{ width: "100%" }}>
                {/* <SelectInput
                  name="assignedTo"
                  placeholder=""
                  onChangeValue={formik.handleChange}
                  onBlurValue={formik.handleBlur}
                  value={formik.values.assignedTo}
                  dropDownData={assignToDropdown}
                  helperText=""
                /> */}

                <UserSearchDropdown
                  name="assignedTo"
                  value={formik.values.assignedTo}
                  collaboratorsData={formik.values.secondarySW}
                  onChange={(event, newValue) => {
                    formik.setFieldValue("assignedTo", newValue);
                  }}
                  onBlur={formik.handleBlur}
                  reset={resetDropdown}
                />

                {formik.touched.assignedTo && formik.errors.assignedTo ? (
                  <div
                    style={{
                      fontSize: "smaller",

                      color: "red",
                      textAlign: "left",
                    }}
                  >
                    {formik.errors.assignedTo}
                  </div>
                ) : null}
              </Stack>
            </Stack>
          </Grid>

          {/* 6  */}
          <Grid item xs={12} sm={6}>
            <Stack>
              <Typography sx={{ textAlign: "left" }}>Collaborator</Typography>

              <Stack sx={{ width: "100%" }}>
                <SelectInput
                  name="secondarySW"
                  placeholder=""
                  onChangeValue={formik.handleChange}
                  onBlurValue={formik.handleBlur}
                  value={formik.values.secondarySW}
                  dropDownData={swData
                    ?.map((item) => {
                      return {
                        value: item.UserID,
                        label: item.Username,
                      };
                    })
                    .filter(
                      (item) => item?.value !== formik?.values?.assignedTo?.id
                    )}
                  helperText=""
                  isMulti={true}
                />

                {formik.touched.secondarySW && formik.errors.secondarySW ? (
                  <div
                    style={{
                      fontSize: "smaller",
                      color: "red",
                      textAlign: "left",
                    }}
                  >
                    {formik.errors.secondarySW}
                  </div>
                ) : null}
              </Stack>
            </Stack>
          </Grid>

          {/* 7  */}
          <Grid item xs={12} sm={6}>
            <Stack>
              <Typography sx={{ textAlign: "left" }}>
                Status<span style={{ color: "red" }}>*</span>
              </Typography>
              <Stack sx={{ width: "100%" }}>
                <SelectInput
                  name="status"
                  placeholder=""
                  onChangeValue={formik.handleChange}
                  onBlurValue={formik.handleBlur}
                  value={formik.values.status}
                  dropDownData={statusData}
                  helperText=""
                  disabled={permissionsIdArray?.includes(22) ? false : true}
                />
                {formik.touched.status && formik.errors.status ? (
                  <div
                    style={{
                      fontSize: "smaller",

                      color: "red",
                      textAlign: "left",
                    }}
                  >
                    {formik.errors.status}
                  </div>
                ) : null}
              </Stack>
            </Stack>
          </Grid>

          {/* 8  */}
          <Grid item xs={12} sm={6}>
            <Stack>
              <Typography sx={{ textAlign: "left" }}>
                Hours Limit<span style={{ color: "red" }}>*</span>
              </Typography>

              <Stack sx={{ width: "100%" }}>
                <NumberInput
                  name="hoursLimit"
                  placeholder=""
                  onChangeValue={formik.handleChange}
                  onBlurValue={formik.handleBlur}
                  value={formik.values.hoursLimit}
                  startIcon={false}
                  helperText={
                    "Maximum number of hours to bill against (as stated on the court order)"
                  }
                />
                {formik.touched.hoursLimit && formik.errors.hoursLimit ? (
                  <div
                    style={{
                      fontSize: "smaller",
                      color: "red",
                      textAlign: "left",
                    }}
                  >
                    {formik.errors.hoursLimit}
                  </div>
                ) : null}
              </Stack>
            </Stack>
          </Grid>

          {/* 9 */}
          <Grid item xs={12}>
            <Stack>
              <Typography sx={{ textAlign: "left" }}>Comments</Typography>

              <Stack sx={{ width: "100%" }}>
                {/* <TextAreaInput
                  name="comments"
                  placeholder=""
                  onChangeValue={formik.handleChange}
                  onBlurValue={formik.handleBlur}
                  value={formik.values.comments}
                  helperText=""
                />
                {formik.touched.comments && formik.errors.comments ? (
                  <div
                    style={{
                      fontSize: "smaller",

                      color: "red",
                      textAlign: "left",
                    }}
                  >
                    {formik.errors.comments}
                  </div>
                ) : null} */}

                <RichTextEditor
                  value={editorValue}
                  onChange={handleEditorChange}
                  // You may want to pass other props like modules and formats here
                />
                {formik.touched.editorContent && formik.errors.editorContent ? (
                  <div
                    style={{
                      fontSize: "smaller",
                      color: "red",
                      textAlign: "left",
                    }}
                  >
                    {formik.errors.editorContent}
                  </div>
                ) : null}
              </Stack>
            </Stack>
          </Grid>

          {/* 23 */}
          <Grid item xs={12} sm={6}>
            <Stack>
              <Typography sx={{ textAlign: "left" }}>
                Case Type<span style={{ color: "red" }}>*</span>
              </Typography>

              <Stack sx={{ width: "100%" }}>
                <SelectInput
                  name="caseType"
                  placeholder=""
                  onChangeValue={formik.handleChange}
                  onBlurValue={formik.handleBlur}
                  value={formik.values.caseType}
                  helperText=""
                  dropDownData={caseTypeData}
                />
                {formik.touched.caseType && formik.errors.caseType ? (
                  <div
                    style={{
                      fontSize: "smaller",
                      color: "red",
                      textAlign: "left",
                    }}
                  >
                    {formik.errors.caseType}
                  </div>
                ) : null}
              </Stack>
            </Stack>
          </Grid>

          {/* 10 */}
          <Grid item xs={12} sm={6}>
            <Stack>
              <Typography sx={{ textAlign: "left" }}>
                Report Due Date
              </Typography>
              <Stack sx={{ width: "100%" }}>
                <DateInput
                  name="reportDueDate"
                  placeholder="reportDueDate"
                  onChangeValue={formik.handleChange}
                  onBlurValue={formik.handleBlur}
                  value={formik.values.reportDueDate}
                  helperText={""}
                />
                {formik.touched.reportDueDate && formik.errors.reportDueDate ? (
                  <div
                    style={{
                      fontSize: "smaller",

                      color: "red",
                      textAlign: "left",
                    }}
                  >
                    {formik.errors.reportDueDate}
                  </div>
                ) : null}
              </Stack>
            </Stack>
          </Grid>

          {/* 10 */}
          <Grid item xs={12} sm={6}>
            <Stack>
              <Typography sx={{ textAlign: "left" }}>Received Date</Typography>
              <Stack sx={{ width: "100%" }}>
                <DateInput
                  name="receivedDate"
                  placeholder="receivedDate"
                  onChangeValue={formik.handleChange}
                  onBlurValue={formik.handleBlur}
                  value={formik.values.receivedDate}
                  helperText={""}
                />
                {formik.touched.receivedDate && formik.errors.receivedDate ? (
                  <div
                    style={{
                      fontSize: "smaller",

                      color: "red",
                      textAlign: "left",
                    }}
                  >
                    {formik.errors.receivedDate}
                  </div>
                ) : null}
              </Stack>
            </Stack>
          </Grid>

          {/* 11 */}
          <Grid item xs={12} sm={6}>
            <Stack>
              <Typography sx={{ textAlign: "left" }}>Adjourned Date</Typography>

              <Stack sx={{ width: "100%" }}>
                <DateInput
                  name="adjournedDate"
                  placeholder="adjournedDate"
                  onChangeValue={formik.handleChange}
                  onBlurValue={formik.handleBlur}
                  value={formik.values.adjournedDate}
                  helperText={""}
                />
                {formik.touched.adjournedDate && formik.errors.adjournedDate ? (
                  <div
                    style={{
                      fontSize: "smaller",

                      color: "red",
                      textAlign: "left",
                    }}
                  >
                    {formik.errors.adjournedDate}
                  </div>
                ) : null}{" "}
              </Stack>
            </Stack>
          </Grid>

          {/* 12 */}
          {/* <Grid item xs={12} sm={6}>
            <Stack>
              <Typography sx={{ textAlign: "left" }}>Adjourned Time</Typography>

              <Stack sx={{ width: "100%" }}>
                <DateInput
                  type="time"
                  name="adjournedTime"
                  placeholder="Enter time"
                  onChangeValue={formik.handleChange}
                  onBlurValue={formik.handleBlur}
                  value={formik.values.adjournedTime}
                  startIcon={false}
                  helperText=""
                />
                {formik.touched.adjournedTime && formik.errors.adjournedTime ? (
                  <div
                    style={{
                      fontSize: "smaller",

                      color: "red",
                      textAlign: "left",
                    }}
                  >
                    {formik.errors.adjournedTime}
                  </div>
                ) : null}
              </Stack>
            </Stack>
          </Grid> */}

          {/* 13 */}
          <Grid item xs={12} sm={6}>
            <Stack>
              <Typography sx={{ textAlign: "left" }}>Court</Typography>
              <Stack sx={{ width: "100%" }}>
                <SelectInput
                  name="court"
                  placeholder=""
                  onChangeValue={formik.handleChange}
                  onBlurValue={formik.handleBlur}
                  value={formik.values.court}
                  dropDownData={courtDropdown}
                  helperText=""
                />
                {formik.touched.court && formik.errors.court ? (
                  <div
                    style={{
                      fontSize: "smaller",
                      color: "red",
                      textAlign: "left",
                    }}
                  >
                    {formik.errors.court}
                  </div>
                ) : null}
              </Stack>
            </Stack>
          </Grid>

          {/* 14 */}
          <Grid item xs={12} sm={6}>
            <Stack>
              <Typography sx={{ textAlign: "left" }}>Judge</Typography>
              <Stack sx={{ width: "100%" }}>
                <SelectInput
                  name="judge"
                  placeholder=""
                  onChangeValue={formik.handleChange}
                  onBlurValue={formik.handleBlur}
                  value={formik.values.judge}
                  dropDownData={judgeDropdown}
                  helperText=""
                />

                {formik.touched.judge && formik.errors.judge ? (
                  <div
                    style={{
                      fontSize: "smaller",
                      color: "red",
                      textAlign: "left",
                    }}
                  >
                    {formik.errors.judge}
                  </div>
                ) : null}
              </Stack>
            </Stack>
          </Grid>

          {/* 15 */}
          <Grid item xs={12} sm={6}>
            <Stack>
              <Typography sx={{ textAlign: "left" }}>
                Report Contact Info
              </Typography>{" "}
              <Stack sx={{ width: "100%" }}>
                <TextInput
                  name="reportContactInfo"
                  placeholder="Enter a name or email address..."
                  onChangeValue={formik.handleChange}
                  onBlurValue={formik.handleBlur}
                  value={formik.values.reportContactInfo}
                  startIcon={false}
                />

                {formik.touched.reportContactInfo &&
                formik.errors.reportContactInfo ? (
                  <div
                    style={{
                      fontSize: "smaller",

                      color: "red",
                      textAlign: "left",
                    }}
                  >
                    {formik.errors.reportContactInfo}
                  </div>
                ) : null}
              </Stack>
            </Stack>
          </Grid>

          {/* 16 */}
          {/* <Grid item xs={12} sm={6}>
            <Stack>
              <Typography sx={{ textAlign: "left" }}>Appearance</Typography>

              <Stack sx={{ width: "100%" }}>
                <SelectInput
                  name="appearance"
                  placeholder=""
                  onChangeValue={formik.handleChange}
                  onBlurValue={formik.handleBlur}
                  value={formik.values.appearance}
                  helperText=""
                  dropDownData={appearanceReport}
                />
                {formik.touched.appearance && formik.errors.appearance ? (
                  <div
                    style={{
                      fontSize: "smaller",
                      color: "red",
                      textAlign: "left",
                    }}
                  >
                    {formik.errors.appearance}
                  </div>
                ) : null}
              </Stack>
            </Stack>
          </Grid> */}

          {/* 17 */}
          <Grid item xs={12} sm={6}>
            <Stack>
              <Typography sx={{ textAlign: "left" }}>Report</Typography>

              <Stack sx={{ width: "100%" }}>
                <SelectInput
                  name="report"
                  placeholder=""
                  onChangeValue={formik.handleChange}
                  onBlurValue={formik.handleBlur}
                  value={formik.values.report}
                  helperText=""
                  dropDownData={appearanceReport}
                />
                {formik.touched.report && formik.errors.report ? (
                  <div
                    style={{
                      fontSize: "smaller",
                      color: "red",
                      textAlign: "left",
                    }}
                  >
                    {formik.errors.report}
                  </div>
                ) : null}
              </Stack>
            </Stack>
          </Grid>

          {/* 18 */}
          <Grid item xs={12} sm={6}>
            <Stack direction={"row"} gap={4}>
              <Typography sx={{ textAlign: "left", width: "25%" }}>
                Ready to Assign?
              </Typography>
              <Stack>
                <CheckboxInput
                  name="readyToAssign"
                  onChangeValue={formik.handleChange}
                  onBlurValue={formik.handleBlur}
                  value={formik.values.readyToAssign}
                  label=""
                  helperText="Check this is the case is ready to be assigned to a worker."
                />
                {formik.touched.readyToAssign && formik.errors.readyToAssign ? (
                  <div
                    style={{
                      fontSize: "smaller",
                      color: "red",
                      textAlign: "left",
                    }}
                  >
                    {formik.errors.readyToAssign}
                  </div>
                ) : null}
              </Stack>
            </Stack>
          </Grid>

          {/* 19 */}
          <Grid item xs={12} sm={6}>
            <Stack direction={"row"} gap={4}>
              <Typography sx={{ textAlign: "left", width: "25%" }}>
                Email Sent?{" "}
              </Typography>

              <Stack>
                <CheckboxInput
                  name="emailSent"
                  onChangeValue={formik.handleChange}
                  onBlurValue={formik.handleBlur}
                  value={formik.values.emailSent}
                  label=""
                  helperText=""
                />
                {formik.touched.emailSent && formik.errors.emailSent ? (
                  <div
                    style={{
                      fontSize: "smaller",
                      color: "red",
                      textAlign: "left",
                    }}
                  >
                    {formik.errors.emailSent}
                  </div>
                ) : null}
              </Stack>
            </Stack>
          </Grid>

          {/* 20 */}
          <Grid item xs={12} sm={6}>
            <Stack direction={"row"} gap={4}>
              <Typography sx={{ textAlign: "left", width: "25%" }}>
                Sealed{" "}
              </Typography>
              <Stack>
                <CheckboxInput
                  name="sealed"
                  onChangeValue={formik.handleChange}
                  onBlurValue={formik.handleBlur}
                  value={formik.values.sealed}
                  label=""
                  helperText="For Simon's use only."
                />
                {formik.touched.sealed && formik.errors.sealed ? (
                  <div
                    style={{
                      fontSize: "smaller",
                      color: "red",
                      textAlign: "left",
                    }}
                  >
                    {formik.errors.sealed}
                  </div>
                ) : null}
              </Stack>
            </Stack>
          </Grid>

          {/* 21 */}
          {(userDetails?.role === 1 || userDetails?.role === 3) && (
            <Grid item xs={12} sm={6}>
              <Stack direction={"row"} gap={4}>
                <Typography sx={{ textAlign: "left", width: "25%" }}>
                  Billed?{" "}
                </Typography>

                <Stack>
                  <CheckboxInput
                    name="billed"
                    onChangeValue={formik.handleChange}
                    onBlurValue={formik.handleBlur}
                    value={formik.values.billed}
                    label=""
                    helperText="For Billing's Use Only"
                  />
                  {formik.touched.billed && formik.errors.billed ? (
                    <div
                      style={{
                        fontSize: "smaller",
                        color: "red",
                        textAlign: "left",
                      }}
                    >
                      {formik.errors.billed}
                    </div>
                  ) : null}
                </Stack>
              </Stack>
            </Grid>
          )}

          {/* 22 */}
          <Grid item xs={12} sm={6}>
            <Stack direction={"row"} gap={4}>
              <Typography sx={{ textAlign: "left", width: "25%" }}>
                Needs Correct Order
              </Typography>

              <Stack>
                <CheckboxInput
                  name="needsCorrectOrder"
                  onChangeValue={formik.handleChange}
                  onBlurValue={formik.handleBlur}
                  value={formik.values.needsCorrectOrder}
                  label=""
                  helperText="These cases need a proper 722c order."
                />
                {formik.touched.needsCorrectOrder &&
                formik.errors.needsCorrectOrder ? (
                  <div
                    style={{
                      fontSize: "smaller",
                      color: "red",
                      textAlign: "left",
                    }}
                  >
                    {formik.errors.needsCorrectOrder}
                  </div>
                ) : null}
              </Stack>
            </Stack>
          </Grid>

          {/* 24 */}
          {/* <Grid item xs={12}>
            <Stack>
              <Typography sx={{ textAlign: "left" }}>
                Preferred Availability
              </Typography>

              <Stack sx={{ width: "100%" }}>
                <TextAreaInput
                  name="preferredAvailability"
                  placeholder=""
                  onChangeValue={formik.handleChange}
                  onBlurValue={formik.handleBlur}
                  value={formik.values.preferredAvailability}
                  helperText=""
                />
                {formik.touched.preferredAvailability &&
                formik.errors.preferredAvailability ? (
                  <div
                    style={{
                      fontSize: "smaller",

                      color: "red",
                      textAlign: "left",
                    }}
                  >
                    {formik.errors.preferredAvailability}
                  </div>
                ) : null}
              </Stack>
            </Stack>
          </Grid> */}

          {/* 25 */}
          <Grid item xs={12} sm={6}>
            <Stack direction={"row"} gap={4}>
              <Typography sx={{ textAlign: "left" }}>Service Type </Typography>

              <Stack>
                <CheckboxInput
                  name="supervisedVisits"
                  onChangeValue={formik.handleChange}
                  onBlurValue={formik.handleBlur}
                  value={formik.values.supervisedVisits}
                  label="Supervised Visits"
                  helperText=""
                />
                {formik.touched.supervisedVisits &&
                formik.errors.supervisedVisits ? (
                  <div
                    style={{
                      fontSize: "smaller",
                      color: "red",
                      textAlign: "left",
                    }}
                  >
                    {formik.errors.supervisedVisits}
                  </div>
                ) : null}

                <CheckboxInput
                  name="therapeuticVisits"
                  onChangeValue={formik.handleChange}
                  onBlurValue={formik.handleBlur}
                  value={formik.values.therapeuticVisits}
                  label="Therapeutic Visits"
                  helperText=""
                />
                {formik.touched.therapeuticVisits &&
                formik.errors.therapeuticVisits ? (
                  <div
                    style={{
                      fontSize: "smaller",
                      color: "red",
                      textAlign: "left",
                    }}
                  >
                    {formik.errors.therapeuticVisits}
                  </div>
                ) : null}

                <CheckboxInput
                  name="observationEvaluation"
                  onChangeValue={formik.handleChange}
                  onBlurValue={formik.handleBlur}
                  value={formik.values.observationEvaluation}
                  label="Observation and Evaluation"
                  helperText=""
                />
                {formik.touched.observationEvaluation &&
                formik.errors.observationEvaluation ? (
                  <div
                    style={{
                      fontSize: "smaller",
                      color: "red",
                      textAlign: "left",
                    }}
                  >
                    {formik.errors.observationEvaluation}
                  </div>
                ) : null}

                <CheckboxInput
                  name="virtualVisit"
                  onChangeValue={formik.handleChange}
                  onBlurValue={formik.handleBlur}
                  value={formik.values.virtualVisit}
                  label="Virtual Visit"
                  helperText=""
                />
                {formik.touched.virtualVisit && formik.errors.virtualVisit ? (
                  <div
                    style={{
                      fontSize: "smaller",
                      color: "red",
                      textAlign: "left",
                    }}
                  >
                    {formik.errors.virtualVisit}
                  </div>
                ) : null}

                <CheckboxInput
                  name="sandwichVisits"
                  onChangeValue={formik.handleChange}
                  onBlurValue={formik.handleBlur}
                  value={formik.values.sandwichVisits}
                  label="Sandwich Visits"
                  helperText=""
                />
                {formik.touched.sandwichVisits &&
                formik.errors.sandwichVisits ? (
                  <div
                    style={{
                      fontSize: "smaller",
                      color: "red",
                      textAlign: "left",
                    }}
                  >
                    {formik.errors.sandwichVisits}
                  </div>
                ) : null}

                <CheckboxInput
                  name="homeStudy"
                  onChangeValue={formik.handleChange}
                  onBlurValue={formik.handleBlur}
                  value={formik.values.homeStudy}
                  label="Home Study"
                  helperText=""
                />
                {formik.touched.homeStudy && formik.errors.homeStudy ? (
                  <div
                    style={{
                      fontSize: "smaller",
                      color: "red",
                      textAlign: "left",
                    }}
                  >
                    {formik.errors.homeStudy}
                  </div>
                ) : null}

                <CheckboxInput
                  name="visitingCoach"
                  onChangeValue={formik.handleChange}
                  onBlurValue={formik.handleBlur}
                  value={formik.values.visitingCoach}
                  label="Visiting Coach"
                  helperText=""
                />
                {formik.touched.visitingCoach && formik.errors.visitingCoach ? (
                  <div
                    style={{
                      fontSize: "smaller",
                      color: "red",
                      textAlign: "left",
                    }}
                  >
                    {formik.errors.visitingCoach}
                  </div>
                ) : null}

                <CheckboxInput
                  name="parentCoordinator"
                  onChangeValue={formik.handleChange}
                  onBlurValue={formik.handleBlur}
                  value={formik.values.parentCoordinator}
                  label="Parent Coordinator"
                  helperText=""
                />
                {formik.touched.parentCoordinator &&
                formik.errors.parentCoordinator ? (
                  <div
                    style={{
                      fontSize: "smaller",
                      color: "red",
                      textAlign: "left",
                    }}
                  >
                    {formik.errors.parentCoordinator}
                  </div>
                ) : null}

                <CheckboxInput
                  name="supervisedAccess"
                  onChangeValue={formik.handleChange}
                  onBlurValue={formik.handleBlur}
                  value={formik.values.supervisedAccess}
                  label="Supervised Access"
                  helperText=""
                />
                {formik.touched.supervisedAccess &&
                formik.errors.supervisedAccess ? (
                  <div
                    style={{
                      fontSize: "smaller",
                      color: "red",
                      textAlign: "left",
                    }}
                  >
                    {formik.errors.supervisedAccess}
                  </div>
                ) : null}

                <CheckboxInput
                  name="dropIns"
                  onChangeValue={formik.handleChange}
                  onBlurValue={formik.handleBlur}
                  value={formik.values.dropIns}
                  label="Drop Ins"
                  helperText=""
                />
                {formik.touched.dropIns && formik.errors.dropIns ? (
                  <div
                    style={{
                      fontSize: "smaller",
                      color: "red",
                      textAlign: "left",
                    }}
                  >
                    {formik.errors.dropIns}
                  </div>
                ) : null}

                <CheckboxInput
                  name="advocacy"
                  onChangeValue={formik.handleChange}
                  onBlurValue={formik.handleBlur}
                  value={formik.values.advocacy}
                  label="Advocacy"
                  helperText=""
                />
                {formik.touched.advocacy && formik.errors.advocacy ? (
                  <div
                    style={{
                      fontSize: "smaller",
                      color: "red",
                      textAlign: "left",
                    }}
                  >
                    {formik.errors.advocacy}
                  </div>
                ) : null}

                <CheckboxInput
                  name="other"
                  onChangeValue={formik.handleChange}
                  onBlurValue={formik.handleBlur}
                  value={formik.values.other}
                  label="Other"
                  helperText=""
                />
                {formik.touched.other && formik.errors.other ? (
                  <div
                    style={{
                      fontSize: "smaller",
                      color: "red",
                      textAlign: "left",
                    }}
                  >
                    {formik.errors.other}
                  </div>
                ) : null}

                <CheckboxInput
                  name="unknown"
                  onChangeValue={formik.handleChange}
                  onBlurValue={formik.handleBlur}
                  value={formik.values.unknown}
                  label="Unknown"
                  helperText=""
                />
                {formik.touched.unknown && formik.errors.unknown ? (
                  <div
                    style={{
                      fontSize: "smaller",
                      color: "red",
                      textAlign: "left",
                    }}
                  >
                    {formik.errors.unknown}
                  </div>
                ) : null}
              </Stack>
            </Stack>
          </Grid>

          {/* 26 */}
          <Grid item xs={12} sm={6}>
            <Stack>
              <Typography sx={{ textAlign: "left" }}>
                Service Location
              </Typography>

              <Stack sx={{ width: "100%" }}>
                <SelectInput
                  name="serviceLocation"
                  placeholder=""
                  onChangeValue={formik.handleChange}
                  onBlurValue={formik.handleBlur}
                  value={formik.values.serviceLocation}
                  helperText=""
                  dropDownData={serviceLocationData}
                />
                {formik.touched.serviceLocation &&
                formik.errors.serviceLocation ? (
                  <div
                    style={{
                      fontSize: "smaller",
                      color: "red",
                      textAlign: "left",
                    }}
                  >
                    {formik.errors.serviceLocation}
                  </div>
                ) : null}
              </Stack>
            </Stack>
          </Grid>
        </Grid>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="center"
          gap={2}
          p={2}
        >
          <ButtonCommon type="submit">Update</ButtonCommon>
          <ButtonCommon
            btnClicked={() => {
              window.history.back();
            }}
          >
            Cancel
          </ButtonCommon>
        </Stack>
      </form>
    </Stack>
  );
};

export default EditCase;
