import React, { useEffect, useState } from "react";
import { TextField, Autocomplete, CircularProgress } from "@mui/material";
import { GET } from "../../api/ApiService";
import EncryptionHelper from "../EncryptionHelper";
import { useTheme } from "@mui/material/styles";

const CaseSearchDropdown = ({
  name,
  label,
  value,
  onChange,
  onBlur,
  error,
  touched,
  reset,
  filtered = false,
}) => {
  const [options, setOptions] = useState([]);
  const [inputValue, setInputValue] = useState("");
  const [loading, setLoading] = useState(false);

  const theme = useTheme();

  useEffect(() => {
    if (reset) {
      setInputValue("");
      setOptions([]);
    }
  }, [reset]);

  const handleInputChange = (event, value) => {
    setInputValue(value);
    if (value.length > 1) {
      setLoading(true);
      GET(`case/search-case?search=${value}`)
        .then((response) => {
          const encryptedData = response?.encryptedData;
          const decryptedData = EncryptionHelper.decryptData(encryptedData);
          console.log(
            "decryptedData of case search dropdown =>",
            decryptedData
          );
          if (!filtered) {
            setOptions(decryptedData);
          } else {
            // Filter the data based on the provided conditions
            const filteredData = decryptedData.filter((item) => {
              const isClosed = item.Status === "Closed";
              const isCaseNumberNull = item.CaseNumber == null; // This checks for both null and undefined
              const isCaseNumberAllZeroes = /^0+$/.test(item.CaseNumber);

              return !isClosed && !isCaseNumberNull && !isCaseNumberAllZeroes;
            });

            // Set the filtered options
            setOptions(filteredData);
          }
        })
        .catch((err) => {
          console.warn(err);
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      setOptions([]);
    }
  };

  return (
    <div>
      <Autocomplete
        name={name}
        options={options}
        getOptionLabel={(option) => option.CaseName}
        onInputChange={handleInputChange}
        inputValue={inputValue}
        onChange={onChange}
        value={value}
        onBlur={onBlur}
        loading={loading}
        sx={{
          "& .MuiOutlinedInput-root": {
            padding: 0,
            paddingLeft: "9px",
            // Hover color using theme
            "&:hover fieldset": {
              borderColor: theme.palette.black.main, // Dynamic hover color
            },
            // Focused color using theme
            "&.Mui-focused fieldset": {
              borderColor: theme.palette.black.main, // Same color for focus
            },
          },
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            label={label}
            placeholder="Search..."
            variant="outlined"
            error={touched && Boolean(error)}
            helperText={touched && error}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <>
                  {loading ? (
                    <CircularProgress color="inherit" size={20} />
                  ) : null}
                  {params.InputProps.endAdornment}
                </>
              ),
            }}
          />
        )}
        clearOnBlur={false}
      />
    </div>
  );
};

export default CaseSearchDropdown;
